<template>
  <component :is="tagName" class="statistics-card" :to="route">
    <div class="d-flex justify-content-between align-items-center h-100 p-3">
      <div class="d-flex flex-column align-items-start justify-content-between">
        <span class="number">{{ number }}</span>
        <span class="description">{{ title }}</span>
      </div>
      <div class="icon">
        <i :class="icon"></i>
      </div>
    </div>
  </component>
</template>
<script>
export default {
  name: 'StatisticsCard',
  props: {
    title: { type: String, default: '' },
    icon: { type: String, default: '' },
    number: { type: [String, Number], default: 0 },
    route: { type: [String, Object], default: '' }
  },
  computed: {
    tagName () {
      return this.route ? 'router-link' : 'div'
    }
  }
}
</script>
<style lang="scss" scoped>
.statistics-card {
  display: block;
  height: 150px;
  background-color: #f6f6f6 !important;
  color: #00443D !important;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .number{
    font-size: 2.25rem;
    font-weight: bold;
  }
  .description{
    font-size: 1.2rem;
    // font-weight: bold;
  }
  .icon{
    font-size: 2.5rem;
  }
}
</style>
