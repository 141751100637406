<template>
  <div>
    <b-row class="mb-4">
      <b-col>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%" class="mb-5"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="mb-4">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'DashboardSkeleton'
}
</script>
