<template>
  <div class="status-card w-100 rounded p-2 px-3" :class="{ [`status-card--${status}`]: status }">
    <h4 class="text-secondary">{{ title }}</h4>
    <h2 class="text-bold">{{ number }}</h2>
  </div>
</template>
<script>
export default {
  name: 'StatusCard',
  props: {
    title: { type: String, default: '' },
    number: { type: [String, Number], default: 0 },
    status: { type: String, default: '' }
  }
}
</script>
<style lang="scss" scoped>
.status-card {
  background-color: #f6f6f6;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>
