<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <dashboard-skeleton />
      </template>

      <b-row cols-md="2" cols="1">
        <b-col v-for="(item, index) in statisticsOptions" :key="index">
          <statistics-card
            :title="item.title"
            :number="item.number"
            :icon="item.icon"
            :route="item.route || ''"
          />
        </b-col>
      </b-row>

      <b-row cols-lg="2" cols="1" class="gap_y_1">
        <b-col>
          <dashboard-card
            :title="$t('orders.orders')"
            :icon="'las la-shopping-cart'"
            class="mb-4"
          >
            <status-card
              v-for="(item, index) in ordersOptions"
              :key="index"
              :title="item.title"
              :number="item.number"
              :status="item.status"
              class="mb-3"
            />
          </dashboard-card>
        </b-col>
        <b-col>
          <dashboard-card class="mb-4">
            <status-card
              v-for="(item, index) in walletOptions"
              :key="index"
              :title="item.title"
              :number="item.number"
              :status="item.status"
              class="mb-3"
            />
          </dashboard-card>
          <!-- <dashboard-card :title="$t('dashboard.profits')" :icon="'las la-chart-line'" class="mb-4">
            <status-card v-for="(item, index) in profitsOptions" :key="index" :title="item.title" :number="item.number" :status="item.status" class="mb-3"/>
          </dashboard-card> -->
        </b-col>
      </b-row>

      <!-- <b-row cols-lg="2" cols="1" class="gap_y_1">
        <b-col>
          <AmChart element="mixes-column" type="mixes-column" :option="charts[0].bodyData"/>
        </b-col>
      </b-row> -->
    </b-skeleton-wrapper>
    <!-- <b-row cols-lg="2" cols="1">
      <b-col v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <AmChart :element="item.type" :type="item.type" :option="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { approximate } from "@/Utils/helper";
import dashBoard from "../services/dashBoard";
import dashboardSkeleton from "../components/dashboardSkeleton.vue";
import statisticsCard from "../components/statisticsCard.vue";
import dashboardCard from "../components/dashboardCard.vue";
import statusCard from "../components/statusCard.vue";
// import AmChart from '../../../components/core/charts/AmChart.vue'
import { mapGetters } from "vuex";

export default {
  name: "mainDash",
  components: {
    dashboardSkeleton,
    statisticsCard,
    dashboardCard,
    statusCard,
    // AmChart
  },
  data() {
    return {
      loading: false,
      stats: null,
      statisticsOptions: [],
      ordersOptions: [],
      profitsOptions: [],
      walletOptions: [],
      orderCharts: {},
      charts: [],
    };
  },
  methods: {
    getStats() {
      this.loading = true;
      dashBoard
        .getAllStats()
        .then((res) => {
          this.stats = res.data.data;
          this.statisticsOptions = [
            {
              title: this.$t("dashboard.totalOrders"),
              number: this.stats?.total_orders || 0,
              icon: "las la-chart-bar",
              route: { name: "orders" },
            },
            {
              title: this.$t("dashboard.totalProducts"),
              number: this.stats?.total_products || 0,
              icon: "las la-boxes",
              route: { name: "products" },
            },
          ];
          this.ordersOptions = [
            {
              title: this.$t("main.status.pending"),
              number: this.stats?.pending_order_count || 0,
              status: "pending",
            },
            {
              title: this.$t("main.status.preparation"),
              number: this.stats?.preparing_order_count || 0,
              status: "preparation",
            },
            {
              title: this.$t("main.status.in_progress"),
              number: this.stats?.in_progress_order_count || 0,
              status: "in_progress",
            },
            {
              title: this.$t("main.status.on_delivery"),
              number: this.stats?.shipping_to_order_count || 0,
              status: "on_delivery",
            },
            {
              title: this.$t("main.status.refused"),
              number: this.stats?.rejected_order_count || 0,
              status: "refused",
            },
            {
              title: this.$t("main.status.success"),
              number: this.stats?.delivered_order_count || 0,
              status: "success",
            },
          ];
          this.profitsOptions = [
            {
              title: this.$t("dashboard.expectedProfits"),
              number: this.$t("main.currency", {
                num: approximate(this.stats?.expected_profits) || 0,
              }),
              status: "warning",
            },
            {
              title: this.$t("dashboard.retainedProfits"),
              number: this.$t("main.currency", {
                num: approximate(this.stats?.retained_profits) || 0,
              }),
              status: "danger",
            },
            {
              title: this.$t("dashboard.withdrawableProfits"),
              number: this.$t("main.currency", {
                num: approximate(this.stats?.withdrawable_profits) || 0,
              }),
              status: "success",
            },
          ];
          this.walletOptions = [
            {
              title: this.$t("dashboard.totalIncome"),
              number: this.$t("main.currency", {
                num: approximate(this.stats?.total_revenue) || 0,
              }),
            },
            {
              title: this.$t("dashboard.walletBalance"),
              number: this.$t("main.currency", {
                num: approximate(this.stats?.wallet_balance) || 0,
              }),
            },
          ];
          this.charts = [
            {
              title: this.$t("orders.orders"),
              type: "mixes-column",
              bodyData: {
                colors: ["#ffd369", "#28a745"],
                labels: [
                  `${this.$t("main.status.pending")}: [bold]{valueY}[/]`,
                  `${this.$t("main.status.success")}: [bold]{valueY}[/]`,
                ],
                xAxis: ["month"],
                yAxis: [
                  this.$t("main.status.pending"),
                  this.$t("main.status.success"),
                ],
                data: this.stats.order_charts.map((item) => {
                  return {
                    month: item.month,
                    [this.$t("main.status.pending")]: item.pending,
                    [this.$t("main.status.success")]: item.success,
                  };
                }),
              },
            },
            // {
            //   title: this.$t('orders.orders'),
            //   type: 'mixes-column',
            //   bodyData: {
            //     colors: ['#ffd369', '#f14336', '#ff9f00'],
            //     labels: [
            //       `${this.$t('main.status.on_delivery')}: [bold]{valueY}[/]`,
            //       `${this.$t('main.status.refused')}: [bold]{valueY}[/]`,
            //       `${this.$t('main.status.preparation')}: [bold]{valueY}[/]`
            //     ],
            //     xAxis: ['month'],
            //     yAxis: [this.$t('main.status.on_delivery'), this.$t('main.status.refused'), this.$t('main.status.preparation')],
            //     data: this.stats.order_charts.map((item) => {
            //       return { month: item.month, [this.$t('main.status.on_delivery')]: item.on_delivery, [this.$t('main.status.refused')]: item.refused, [this.$t('main.status.preparation')]: item.preparation }
            //     })
            //   }
            // }
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
    this.getStats();
  },
  computed: {
    ...mapGetters({ storeActive: "getStoreActive" }),
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.login == "true") {
      localStorage.removeItem("userToken");
      localStorage.removeItem("userInfo");
      next({ name: "login" });
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.card-info {
  background: #eee;
  height: 150px;
  color: #00443d;
  .number {
    font-size: 2rem;
    font-weight: bold;
  }
  .description {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .icon {
    font-size: 2rem;
  }
}
</style>
