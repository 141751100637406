<template>
  <div class="dashboard-card rounded bg-white p-3">
    <h4 v-if="title && icon" class="text-secondary border-bottom mb-3">
      <span v-if="icon" class="px-1"><i :class="icon"></i></span>
      <span>{{ title }}</span>
    </h4>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'DashboardCard',
  props: {
    title: { type: String, default: '' },
    icon: { type: String, default: '' }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-card {
  h4 {
    font-size: 1.4rem;
  }
}
</style>
